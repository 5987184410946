<template>
  <div class="container">
    <ShareDialog
      :visibleShareDialog="visibleShareDialog"
      :fullPractice="fullPractice"
      @toggleVisibleShareDialog="toggleVisibleShareDialog"
    />
    <PracticeBreakTimeDialog
      v-if="currentPractice"
      :practice="currentPractice.practice"
      :defaultMinutes="Math.floor(currentPractice.break_seconds / 60)"
      :defaultSeconds="currentPractice.break_seconds % 60"
      @toggleVisiblePracticeBreakTimeDialog="
        toggleVisiblePracticeBreakTimeDialog
      "
      @submitPracticeBreakTime="updatePracticesBreakTime"
      :visiblePracticeBreakTimeDialog="visiblePracticeBreakTimeDialog"
    />
    <Breadcrumb />
    <el-alert
      v-if="fullPractice.user_exams.length > 0"
      type="error"
      :closable="false"
    >
      <template slot="title">
        <span>{{ $t("practice.form.alreadyTakenExam") }}</span>
      </template>
    </el-alert>
    <h1>
      {{ this.pageTitle }}
    </h1>
    <hr class="separate-line" />
    <div v-if="fullPractice">
      <el-form label-width="100px" :model="fullPractice" ref="practiceForm">
        <el-form-item>
          <template slot="label"
            ><el-row>{{ $t("practice.form.name") }}</el-row></template
          >
          <el-row>
            <el-col :span="20">
              <el-input v-model="fullPractice.exam.title" />
            </el-col>
            <el-col style="text-align: right" :span="4">
              <el-button
                type="primary"
                @click="
                  () => {
                    toggleVisibleShareDialog(true);
                  }
                "
              >
                <i class="fas fa-lock-open" />
                {{ $t("practice.form.share") }}
              </el-button>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item>
          <template slot="label"
            ><el-row>{{ $t("practice.form.limit") }}</el-row></template
          >
          <el-row>
            <el-col :span="6">
              <el-radio-group v-model="fullPractice.has_expire">
                <el-radio :label="0">{{
                  $t("practice.form.noneLimit")
                }}</el-radio>
                <el-radio :label="1">{{
                  $t("practice.form.hasLimit")
                }}</el-radio>
              </el-radio-group>
            </el-col>
            <template v-if="fullPractice.has_expire">
              <el-col :span="8">
                <el-date-picker
                  type="date"
                  style="width: 100%"
                  v-model="fullPractice.expire_start"
                />
              </el-col>
              <el-col style="text-align: center;" :span="2">～</el-col>
              <el-col :span="8">
                <el-date-picker
                  type="date"
                  style="width: 100%"
                  v-model="fullPractice.expire_end"
                />
              </el-col>
            </template>
          </el-row>
        </el-form-item>
        <el-form-item>
          <template slot="label"
            ><el-row>{{ $t("practice.form.show") }}</el-row></template
          >
          <el-row>
            <el-radio-group v-model="fullPractice.show">
              <el-radio label="COMPLETION_ONLY">{{
                $t("practice.form.completionOnly")
              }}</el-radio>
              <el-radio label="CORRECTNESS">{{
                $t("practice.form.correctness")
              }}</el-radio>
              <el-radio label="CORRECTNESS_AND_EXPLANATION">{{
                $t("practice.form.correctnessAndExplanation")
              }}</el-radio>
            </el-radio-group>
          </el-row>
        </el-form-item>
        <el-form-item>
          <template slot="label"
            ><el-row>{{
              $t("practice.form.practiceTemplate")
            }}</el-row></template
          >
          <el-row>
            <el-radio-group v-model="fullPractice.template_type">
              <el-radio label="ORIGINAL">{{
                $t("practice.form.byQuestion")
              }}</el-radio>
              <el-radio label="SAT">SAT</el-radio>
              <el-radio label="ACT">ACT</el-radio>
              <el-radio label="TOEFL">TOEFL</el-radio>
            </el-radio-group>
          </el-row>
        </el-form-item>
        <el-form-item>
          <template slot="label"
            ><el-row>{{ $t("practice.form.scoringType") }}</el-row></template
          >
          <el-row>
            <el-radio-group
              :value="fullPractice.scoring_type"
              @input="setScoringType"
            >
              <el-radio label="DEFAULT">{{
                $t("practice.form.none")
              }}</el-radio>
              <el-radio label="SAT">SAT</el-radio>
              <el-radio label="ACT">ACT</el-radio>
              <el-radio label="TOEFL">TOEFL</el-radio>
            </el-radio-group>
          </el-row>
        </el-form-item>
        <el-form-item v-if="isScoringTypeSatOrAct">
          <template slot="label"
            ><el-row>{{
              $t("practice.form.scoringStandard")
            }}</el-row></template
          >
          <el-row>
            <el-select
              style="width: 100%"
              :value="fullPractice.scoring_id || null"
              @change="
                scoring_id => {
                  fullPractice.scoring_id = scoring_id;
                }
              "
            >
              <el-option
                :key="test.id"
                v-for="test in tests"
                :value="test.id"
                :label="test.full_name"
              />
            </el-select>
          </el-row>
        </el-form-item>
      </el-form>
      <el-row type="flex" justify="center">
        <el-col :span="3" style="text-align: center">
          <el-button @click="updateFullPractice" type="primary">
            {{ $t("button.save") }}
          </el-button>
        </el-col>
        <el-col :span="3" style="text-align: center">
          <router-link
            :to="{
              name: 'FullPracticeResults',
              params: { fullPracticeId: $route.params.fullPracticeId }
            }"
          >
            <el-button type="primary">
              <i class="fa fa-eye" />{{ $t("practice.form.testResults") }}
            </el-button>
          </router-link>
        </el-col>
        <el-col :span="3" style="text-align: center">
          <router-link
            :to="{
              name: 'FullPractice',
              params: { fullPracticeId: $route.params.fullPracticeId }
            }"
          >
            <el-button type="primary">
              <i class="fa fa-eye" />{{ $t("practice.form.preview") }}
            </el-button>
          </router-link>
        </el-col>
      </el-row>
      <hr class="separate-line" />
      <h2>Sections</h2>
      <template v-if="isScoringTypeSatOrAct">
        <div>
          <el-row class="staticSectionRow title">
            <el-col :span="2">#</el-col>
            <el-col :span="4">{{ $t("practice.form.name") }}</el-col>
            <el-col :span="18">{{ $t("practice.form.section") }}</el-col>
          </el-row>
          <div
            :key="practice.id"
            v-for="(practice, index) in fullPractice.compose_practice_practices"
          >
            <el-row class="staticSectionRow td">
              <el-col :span="2">{{ index + 1 }}</el-col>
              <el-col :span="4">{{ practice.label }}</el-col>
              <el-col :span="18">
                <el-select
                  style="width: 100%; margin-bottom: 8px"
                  v-model="practice.practiceId"
                >
                  <el-option
                    :value="null"
                    :label="$t('practice.form.pleaseChoosePractice')"
                  />
                  <el-option
                    :key="practice.id"
                    v-for="practice in practices"
                    :value="practice.id"
                    :label="practice.exam.full_name"
                  />
                </el-select>
              </el-col>
            </el-row>
            <el-row
              v-if="index < fullPractice.compose_practice_practices.length - 1"
              class="staticSectionRow td"
            >
              <el-col :span="2"
                ><div style="visibility: hidden">Empty Block</div></el-col
              >
              <el-col :span="4">{{ $t("practice.break") }}</el-col>
              <el-col :span="18">
                <el-input
                  style="width: 50%"
                  placeholder="Minutes"
                  v-model="practice.minutes"
                >
                  <template slot="prepend">
                    <i class="el-icon-alarm-clock" />
                  </template>
                  <template slot="append">{{
                    $t("practice.form.min")
                  }}</template>
                </el-input>
                <el-input
                  style="width: 50%"
                  placeholder="Seconds"
                  v-model="practice.seconds"
                >
                  <template slot="append">{{
                    $t("practice.form.sec")
                  }}</template>
                </el-input>
              </el-col>
            </el-row>
          </div>
        </div>
      </template>
      <template v-else>
        <el-row>
          <el-col style="text-align: center">
            <router-link
              :to="{
                name: 'AddPracticesToFullPractice',
                params: { fullPracticeId: $route.params.fullPracticeId }
              }"
            >
              <el-button style="width: 600px" type="primary">
                <span>{{
                  `${$t("practice.form.currentSectionCount")}${
                    fullPractice.compose_practice_practices.length
                  }`
                }}</span>
                &nbsp;&nbsp;
                <span style="margin-left: 16px">
                  {{ $t("practice.form.addNewSection")
                  }}<i class="fa fa-plus" />
                </span>
              </el-button>
            </router-link>
          </el-col>
        </el-row>
        <el-row
          v-if="
            !hasChangedPracticeOrder &&
              fullPractice.compose_practice_practices.length > 0
          "
        >
          <el-col>
            <span style="color: red; font-size: 12px"
              >* {{ $t("practice.form.dragSectionsToAdjustOrder") }}</span
            >
          </el-col>
        </el-row>
        <el-row
          v-if="hasChangedPracticeOrder"
          :gutter="4"
          type="flex"
          justify="center"
        >
          <el-col :span="3">
            <el-button @click="updatePracticeOrder" type="primary">
              {{ $t("practice.form.saveSectionOrder") }}
            </el-button>
          </el-col>
          <el-col :span="3">
            <el-button @click="resetPracticeOrder" type="danger">
              {{ $t("practice.form.cancelSequenceChange") }}
            </el-button>
          </el-col>
        </el-row>
        <el-row>
          <draggable
            :disabled="false"
            :list="fullPractice.compose_practice_practices"
            class="list-group"
            ghost-class="ghost"
            @start="dragging = true"
            @end="dragging = false"
            :move="movePracticeOrder"
          >
            <div
              class="list-group-item"
              v-for="(practice,
              index) in fullPractice.compose_practice_practices"
              :key="practice.id"
            >
              <el-row class="sectionRow">
                <el-col :span="1">{{ index + 1 }}</el-col>
                <el-col :span="4">
                  <kbd>{{ `Section ${index + 1}` }}</kbd>
                </el-col>
                <el-col :span="12">
                  {{ practice.practice.exam.full_name }}
                </el-col>
                <el-col :span="2">
                  <router-link
                    :to="{
                      name: 'EditPractices',
                      params: { practiceId: practice.practice.id }
                    }"
                  >
                    <i class="fas fa-edit action-icon" />
                  </router-link>
                  <span @click="removePractice(practice.id)">
                    <i class="fas fa-trash-alt warning-icon" />
                  </span>
                </el-col>
              </el-row>
              <el-row
                style="border-top: 1px solid rgba(0, 0, 0, 0.125)"
                class="sectionRow"
                v-if="
                  index < fullPractice.compose_practice_practices.length - 1
                "
              >
                <el-col :span="5"
                  ><div style="visibility: hidden">Empty Block</div></el-col
                >
                <el-col :span="12">
                  {{ `Break: ${instant.formatSecondToMMSS(practice.break_seconds)}` }}
                </el-col>
                <el-col :span="2">
                  <span
                    @click="
                      toggleVisiblePracticeBreakTimeDialog(true, practice)
                    "
                  >
                    <i class="fas fa-edit action-icon" />
                  </span>
                </el-col>
              </el-row>
            </div>
          </draggable>
        </el-row>
      </template>
    </div>
  </div>
</template>

<script>
import { instant } from "@ivy-way/material";
import draggable from "vuedraggable";
import Breadcrumb from "@/components/Breadcrumb";
import ShareDialog from "@/components/practices/ShareDialog";
import PracticeBreakTimeDialog from "@/components/practices/PracticeBreakTimeDialog";
import testApi from "@/apis/tests.js";
import practiceApi from "@/apis/practices";
import fullPracticesApi from "@/apis/fullPractices";
import Practice from "@/views/practices/Practice";
import FullPractice from "@/views/practices/FullPractice";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },
  components: {
    Breadcrumb,
    ShareDialog,
    PracticeBreakTimeDialog,
    draggable
  },
  props: [],
  data() {
    return {
      fullPractice: null,
      originalPracticesOrder: [],
      currentPractice: null,
      hasChangedPracticeOrder: false,
      visibleShareDialog: false,
      visibleCopyDialog: false,
      visiblePracticeBreakTimeDialog: false,
      practices: [],
      tests: [],
      satSections: [
        { practiceId: null, label: "Reading", scoringType: "reading", minutes: 0, seconds: 0 },
        { practiceId: null, label: "Writing", scoringType: "writing", minutes: 0, seconds: 0 },
        { practiceId: null, label: "Math", scoringType: "math", minutes: 0, seconds: 0 },
        {
          practiceId: null,
          label: "Math Calculator",
          scoringType: "math_calculator",
          minutes: 0,
          seconds: 0
        }
      ],
      actSections: [
        { practiceId: null, label: "English", scoringType: "english", minutes: 0, seconds: 0 },
        { practiceId: null, label: "Math", scoringType: "math", minutes: 0, seconds: 0 },
        { practiceId: null, label: "Reading", scoringType: "reading", minutes: 0, seconds: 0 },
        { practiceId: null, label: "Science", scoringType: "science", minutes: 0, seconds: 0 }
      ]
    };
  },
  computed: {
    instant() {
      return instant;
    },
    pageTitle() {
      return `${this.$t("pageTitle.editFullPractices")}`;
    },
    isScoringTypeSatOrAct() {
      return (
        this.fullPractice.scoring_type === "SAT" ||
        this.fullPractice.scoring_type === "ACT"
      );
    }
  },
  watch: {
    "fullPractice.scoring_type"() {
      if (this.isScoringTypeSatOrAct) {
        this.fetchTests();
        const sections = this[
          `${this.fullPractice.scoring_type.toLowerCase()}Sections`
        ].map(section => {
          const practice = this.fullPractice.compose_practice_practices.find(
            ({ scoring_type }) => scoring_type === section.scoringType
          );
          const { practice_id, break_seconds } = practice || {};
          return {
            ...section,
            practiceId: practice_id || null,
            minutes: Math.floor(break_seconds / 60) || 0,
            seconds: break_seconds % 60 || 0
          };
        });
        this.fullPractice = new FullPractice({
          ...this.fullPractice,
          compose_practice_practices: sections
        });
      } else {
        this.fullPractice = new FullPractice({
          ...this.fullPractice,
          compose_practice_practices: this.originalPracticesOrder
        });
      }
    }
  },
  async created() {
    this.fetchPractices();
    await this.fetchFullPractice();
    if (this.isScoringTypeSatOrAct) {
      this.fetchTests();
    }
  },
  methods: {
    async fetchFullPractice() {
      const { compose_practice } = await fullPracticesApi.fetchFullPractice(
        this.$route.params.fullPracticeId
      );
      this.fullPractice = new FullPractice(compose_practice);
      this.originalPracticesOrder = this.fullPractice.compose_practice_practices;
    },
    async fetchPractices() {
      const { practices } = await practiceApi.fetchPractices();
      this.practices = practices.map(practice => new Practice(practice));
    },
    async fetchTests() {
      const tests = await testApi.getTestList(this.fullPractice.scoring_type);
      this.tests = tests.map(test => ({
        ...test,
        id: test[`${this.fullPractice.scoring_type.toLowerCase()}_id`]
      }));
    },
    async setScoringType(scoringType) {
      try {
        await this.$confirm(
          "更改計分方式會移除當前所有 Sections，請問要繼續嗎？",
          this.$t("message.notice"),
          {
            confirmButtonText: this.$t("message.continue"),
            cancelButtonText: this.$t("message.cancel"),
            type: "warning"
          }
        );
      } catch (e) {
        return false;
      }
      this.fullPractice.scoring_type = scoringType;
      this.scoring_id = 0;

      await fullPracticesApi.removeAllPractices(this.fullPractice.id);
    },
    toggleVisibleShareDialog(type) {
      this.visibleShareDialog = type;
    },
    toggleVisiblePracticeBreakTimeDialog(type, practice) {
      this.currentPractice = type ? practice : null;
      this.visiblePracticeBreakTimeDialog = type;
    },
    movePracticeOrder() {
      this.hasChangedPracticeOrder = true;
    },
    async updatePracticeOrder() {
      const practices = this.fullPractice.compose_practice_practices.map(
        (practice, index) => ({
          order: index + 1,
          id: practice.id
        })
      );
      try {
        await fullPracticesApi.refreshPracticesOrder(practices);
        this.hasChangedQuestionOrder = false;
        this.fetchFullPractice();
        this.$message.success(this.$t("message.update_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    async updatePracticesBreakTime(breakSeconds, scoring_type = null) {
      try {
        const practice = await fullPracticesApi.updatePractices(
          this.currentPractice.id,
          {
            practice_id: this.currentPractice.id,
            break_seconds: breakSeconds,
            scoring_type
          }
        );
        await this.fetchFullPractice();
        this.$message.success(this.$t("message.update_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    resetPracticeOrder() {
      this.fullPractice = {
        ...this.fullPractice,
        compose_practice_practices: this.originalPracticesOrder.map(
          practice => ({
            ...practice
          })
        )
      };
      this.hasChangedPracticeOrder = false;
    },
    async updateFullPractice() {
      if (
        this.fullPractice.has_expire &&
        !this.fullPractice.expire_start &&
        !this.fullPractice.expire_end
      ) {
        this.$message.warning(this.$t("practice.form.pleaseFillValidDeadline"));
        return;
      }

      if (this.isScoringTypeSatOrAct) {
        if (!this.fullPractice.scoring_id) {
          this.$message.warning(
            this.$t("practice.form.pleaseChooseScoringType")
          );
          return;
        }

        if (!(await this.saveSections())) return;
      }

      try {
        await fullPracticesApi.updateFullPractice(this.fullPractice);
        this.$message.success(this.$t("message.update_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    async removePractice(id) {
      try {
        await fullPracticesApi.removePractices(id);
        await this.fetchFullPractice();
        this.$message.success(this.$t("message.update_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    async saveSections() {
      try {
        await fullPracticesApi.removeAllPractices(this.fullPractice.id);
      } catch (e) {
        return false;
      }

      const isSelectedPractice = index =>
        this.fullPractice.compose_practice_practices[index].practiceId;

      const getSectionPractice = index => {
        const practice = this.fullPractice.compose_practice_practices[index];
        return {
          practice_id: practice.practiceId,
          break_seconds: practice.minutes * 60 + Number(practice.seconds),
          scoring_type: practice.scoringType
        };
      };

      try {
        isSelectedPractice(0) &&
          (await fullPracticesApi.addPractices(
            this.$route.params.fullPracticeId,
            getSectionPractice(0)
          ));
        isSelectedPractice(1) &&
          (await fullPracticesApi.addPractices(
            this.$route.params.fullPracticeId,
            getSectionPractice(1)
          ));
        isSelectedPractice(2) &&
          (await fullPracticesApi.addPractices(
            this.$route.params.fullPracticeId,
            getSectionPractice(2)
          ));
        isSelectedPractice(3) &&
          (await fullPracticesApi.addPractices(
            this.$route.params.fullPracticeId,
            getSectionPractice(3)
          ));
        return true;
      } catch (e) {
        return false;
      }
    }
  }
};
</script>

<style>
.el-form-item {
  margin-bottom: 0px;
}

.list-group-item {
  padding: 0px;
  cursor: move;
}

.sectionRow {
  padding: 10px 20px 5px 20px;
}

.title {
  font-weight: bold;
  color: #909399;
}

.td {
  color: #606266;
}

.staticSectionRow {
  padding: 8px 20px;
  border-bottom: 1px solid #ebeef5;
  font-size: 14px;
}
</style>
